import React from 'react';
import PropTypes from 'prop-types';
import GitHubButton from 'react-github-btn';
import { graphql } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { accessibleContainerAsLink } from '../../util/Accessibility';

const ProjectCard = ({
  id,
  title,
  isFeatured,
  featuredImage,
  summary,
  githubRepo,
  website,
  cardColumns,
}) => {
  const url = website ? website : githubRepo;
  const cardGridNumber = cardColumns || 12;
  const containerAsLinkProps = accessibleContainerAsLink(url, false);
  return (
    <article
      className={`card card-project${
        isFeatured ? ' is-featured' : ''
        } col-md-4 col-sm-${cardGridNumber} ${cardGridNumber === 6 ? 'card-secondary-layout' : ''} col-xs-12`}
    >
      <div className="card-content" {...containerAsLinkProps}>
        <a
          href={url}
          className={`${
            featuredImage.src
              ? 'image-link hover-jump-small-grand-child project'
              : ''
            }`}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex="-1"
        >
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage.src,
                alt: featuredImage.alt ? featuredImage.alt : 'Project picture'
              }}
            />
          </div>
        </a>
        <div className="content-description">
          <div className="card-description-wrapper">
            <header>
              <div className="post-meta">
                <a
                  href={url}
                  target="_blank"
                  className="card-title-link hover-underline"
                  rel="noopener noreferrer"
                  tabIndex="-1"
                >
                  <h3 className="card-title">{title}</h3>
                </a>
              </div>
            </header>
            <p className="card-description">
              {summary ? summary.substr(0, 400) : null}
            </p>
          </div>
          <div className="buttons">
            <a
              className="button button-more"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="-1"
            >
              <span className="sr-only">{title}.</span>
              Find out more
              <span className="sr-only">, link opens in a new window</span>
            </a>
            <div className="github-button">
              <GitHubButton
                href={githubRepo}
                data-icon="octicon-star"
                data-show-count="true"
                aria-label={`Star ${title} on GitHub`}
                tabIndex="-1"
              >
                Star
              </GitHubButton>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

ProjectCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isFeatured: PropTypes.bool,
  featuredImage: PropTypes.object,
  summary: PropTypes.string,
  githubRepo: PropTypes.string.isRequired,
  website: PropTypes.string,
};

export default ProjectCard;

export const query = graphql`
  fragment ProjectCardFragment on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      summary
      templateKey
      date(formatString: "MMMM DD, YYYY")
      github
      website
      featuredpost
    }
    ...featuredImageProjectCard
  }
`;

export const featuredImageProjectCard = graphql`
  fragment featuredImageProjectCard on MarkdownRemark {
    frontmatter {
      featuredimage {
        src {
          childImageSharp {
            fixed(height: 160, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        alt
      }
    }
  }
`;
