import React from 'react';
import PropTypes from 'prop-types';
import TagLink from './TagLink';

const TagsBox = ({ title, tags }) => {
  return (
    <section className="tags">
      {tags && tags.length ? (
        <div className="tags-wrapper">
          <p className="tags-title">{title}:</p>   
          <ul className="taglist tags-blocks">  
          {tags.map(tag => (
            <li key={tag + `tag`}>
              <TagLink tag={tag} />
            </li>
          ))}
          </ul>
        </div>
      ) : null}
    </section>
  );
};

TagsBox.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.array,
};

export default TagsBox;
