import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import { accessibleContainerAsLink } from '../../util/Accessibility';

const EventCard = ({
  id,
  slug,
  title,
  eventDate,
  isFeatured,
  featuredImage,
  summary,
  location,
  speakers,
  cardColumns,
  skipThumbnail,
}) => {
  const cardGridNumber = cardColumns || 12;
  const containerAsLinkProps = accessibleContainerAsLink(slug);
  return (
    <article
      className={`card card-event${
        isFeatured ? ' is-featured' : ''
        } col-md-${cardGridNumber} ${
        cardGridNumber === 12 ? 'card-secondary-layout' : ''
        } ${
        skipThumbnail ? 'no-thumbnail' : ''}
        `}
    >
      <div className="card-content" {...containerAsLinkProps}>
        { skipThumbnail ? null :
          <Link
            to={slug}
            className="image-link hover-highlight-image event background-graph-paper"
            tabIndex="-1"
          >
            <div className="image-wrapper">
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredImage.src,
                  alt: featuredImage.alt ? featuredImage.alt : 'Event picture',
                }}
              />
            </div>
          </Link>
        }
        <div className="content-description">
          <div className="card-description-wrapper">
            <header>
              <div className="post-meta">
                <div className="is-details">
                  <p className="is-date">
                    {eventDate}
                  </p>
                  <p className="is-location">
                    {location && `${location}`}
                  </p>
                </div>
                <Link to={slug} className="card-title-link hover-underline" tabIndex="-1">
                  <h3 className="card-title">{title}</h3>
                </Link>
                {speakers && (
                  <span className="is-speaker">
                    Speaker{speakers.length > 1 ? 's' : ''}:
                    {speakers.map((s, index) => (
                      <span key={s}>
                        {index ? ', ' : ' '}
                        <Link
                          to={`/authors/${kebabCase(s)}/`}
                          tabIndex="-1"
                        >
                          {s}
                        </Link>
                      </span>
                    ))}
                  </span>
                )}
              </div>
            </header>
            <p className="card-description">
              {summary ? summary.substr(0, 400) : null}
            </p> 
          </div>
          <div className="card-read-more">
            <Link
              className="button button-more"
              to={slug}
              tabIndex="-1"
            >
              <span className="sr-only">{title}.</span>
              Keep reading
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

EventCard.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  isFeatured: PropTypes.bool,
  featuredImage: PropTypes.object,
  summary: PropTypes.string,
  location: PropTypes.string,
  speakers: PropTypes.array,
};

export default EventCard;

export const EventCardFragment = graphql`
  fragment EventCardFragment on MarkdownRemark {
    id
    fields {
      slug
    }
    frontmatter {
      title
      summary
      templateKey
      date(formatString: "MMMM DD, YYYY")
      featuredpost
      location
      speakers
      dateRange
    }
    ...featuredImage
  }
`;
