import { navigate } from 'gatsby';

export const accessibleContainerAsLink = (
  link = '/',
  isInternalLink = true
) => ({
  onKeyPress: () => isInternalLink ? navigate(link) : window.open(link, '_blank'),
  tabIndex: '0',
  role: 'link'
});
