import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PropTypes from 'prop-types';
import { TagPageTemplate } from './tag';
import TagsBox from '../components/TagsBox';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const CategoryPage = ({ pageContext, data, location }) => {
  const { siteMetadata } = data.site;
  const { category } = data;
  const { title, description } = category.frontmatter;
  const { edges: posts } = data.posts;
  const { edges: events } = data.events;
  const { edges: projects } = data.projects;
  const tag = pageContext.tag.toLowerCase();
  const totalCount =
    (posts ? posts.length : 0) +
    (events ? events.length : 0) +
    (projects ? projects.length : 0);

  return (
    <Layout pageType="TagPage">
      <div className="main tag-page">
        <section>
          <Helmet title={`${title} | ${siteMetadata.title}`} />
          <GatsbySeo
            canonical={location.href}
            openGraph={{
              title: `${title} | ${siteMetadata.title}`,
              url: `${location.href}`,
            }}
          />
          <div className="container content">
            <div className="hero grid-container flex-start">
              <h1 className="page-title off-sm-1 col-sm-10 off-md-0 col-md-12">#{title}</h1>
              {totalCount > 0 ? (
                <p className="item-count off-sm-1 col-sm-10 off-md-0 col-md-12">
                  total {totalCount} item{totalCount === 1 ? '' : 's'}
                </p>
              ) : (
                <div>
                  <h2>Ooops!</h2>
                  <p>It looks that there is no content here... ¯\_(ツ)_/¯</p>
                </div>
              )}
            </div>
          </div>
        </section>
        <TagPageTemplate
          tag={tag}
          category={category.frontmatter.title}
          posts={posts}
          events={events}
          projects={projects}
        />
        <TagsBox title={'Related tags'} tags={category.frontmatter.related} />
      </div>
    </Layout>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CategoryPage;

export const categoryPageQuery = graphql`
  query CategoryPageQuery($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    category: markdownRemark(
      frontmatter: { templateKey: { eq: "category" }, tag: { eq: $tag } }
    ) {
      id
      html
      frontmatter {
        title
        related
      }
    }
    posts: allMarkdownRemark(
      limit: 9
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
    events: allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "project" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
  }
`;
