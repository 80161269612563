import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import ItemsList from '../components/ItemsList';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';

const { deslugify } = require('../util/Deslugify');

export const TagPageTemplate = ({ tag, category, posts, events, projects }) => {
  return (
    <div className="grid-container">
      {posts && posts.length > 0 && (
        <section className="category-section posts col-sm-10 col-md-12">
          <h2 className="section-title title-white">Blog</h2>
          <div className="content">
            <ItemsList initialItems={posts} />
          </div>
          {category ? (
            <div className="button-wrapper">
              <Link
                className="button-more button-primary button-blog"
                to={`/blog/${kebabCase(tag)}/`}
              >
                More posts in {category}
              </Link>
            </div>
          ) : (
            <div className="button-wrapper">
              <Link
                className="button-more button-primary button-blog"
                to="/blog"
              >
                All posts
              </Link>
            </div>
          )}
        </section>
      )}
      {events && events.length > 0 && (
        <section className="category-section events col-sm-10 col-md-12">
          <h2 className="section-title">Events</h2>
          <div className="content">
            <ItemsList initialItems={events} />
          </div>
          <div className="button-wrapper">
            <Link
              className="button-more button-primary button-events"
              to="/events"
            >
              All events
            </Link>
          </div>
        </section>
      )}
      {projects && projects.length > 0 && (
        <section className="category-section projects col-sm-10 col-md-12">
          <h2 className="section-title">Open source</h2>
          <div className="content">
            <ItemsList initialItems={projects} cardColumns={4} />
          </div>
          <div className="button-wrapper">
            <Link
              className="button-more button-primary button-projects"
              to="/open-source"
            >
              All open source
            </Link>
          </div>
        </section>
      )}
    </div>
  );
};

TagPageTemplate.propTypes = {
  tag: PropTypes.string.isRequired,
  category: PropTypes.string,
  posts: PropTypes.array,
  events: PropTypes.array,
  projects: PropTypes.array,
};

const TagPage = ({ pageContext, data }) => {
  const { siteMetadata } = data.site;
  const { allMarkdownRemark: page } = data;
  const { edges: posts } = data.posts;
  const { edges: events } = data.events;
  const { edges: projects } = data.projects;
  const tag = pageContext.tag.toLowerCase();

  return (
    <Layout pageType="TagPage">
      <div className="main tag-page">
        <section>
          <Helmet title={`${tag} | ${siteMetadata.title}`} />
          <div className="container content">
            <div className="hero grid-container flex-start">
              <h1 className="page-title off-sm-1 col-sm-10 off-md-0 col-md-12">#{deslugify(tag)}</h1>
              {page.totalCount > 0 ? (
                <p className="item-count off-sm-1 col-sm-10 off-md-0 col-md-12">
                  total {page.totalCount} item{page.totalCount === 1 ? '' : 's'}
                </p>
              ) : (
                <div>
                  <h2>Ooops!</h2>
                  <p>It looks that there is no content here... ¯\_(ツ)_/¯</p>
                </div>
              )}
            </div>
          </div>
        </section>
        <TagPageTemplate
          tag={tag}
          posts={posts}
          events={events}
          projects={projects}
        />
        <section>
          <div className="container">
            <div className="content">
              <p className="button-tags-wrapper">
                <Link className="taglink button-more button-primary button-tags" to="/tags/">Browse all tags</Link>
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

TagPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default TagPage;

export const tagPageQuery = graphql`
  query TagPageQuery($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
    }
    posts: allMarkdownRemark(
      limit: 100
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
    events: allMarkdownRemark(
      sort: { fields: [frontmatter___date, frontmatter___title], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "project" }, tags: { in: [$tag] } }
      }
    ) {
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
  }
`;
