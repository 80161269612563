import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import plFlag from '../../img/pl.svg';
import { accessibleContainerAsLink } from '../../util/Accessibility';
import { SvgIcon } from '../SvgIcon';


const BlogPostCard = ({
  id,
  slug,
  title,
  publicationDate,
  isFeatured,
  featuredImage,
  summary,
  authors,
  timeToRead,
  language,
  cardColumns,
  skipThumbnail,
}) => {
  const cardGridNumber = cardColumns || 12;
  const containerAsLinkProps = accessibleContainerAsLink(slug);
  return (
    <article
      className={`card card-blog${
        isFeatured ? ' is-featured' : ''
      } col-md-${cardGridNumber} ${
        cardGridNumber === 12 ? 'card-secondary-layout' : ''
      } ${
        skipThumbnail ? 'no-thumbnail' : ''
      }`}
    >
      <div className="card-content" {...containerAsLinkProps}>
        {skipThumbnail ? null :
        <Link
          to={slug}
          className="image-link hover-highlight-image blog background-graph-paper"
          tabIndex="-1"
        >
          <div className="image-wrapper">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredImage.src,
                alt: featuredImage.alt ? featuredImage.alt : 'Article picture',
              }}
            />
          </div>
        </Link>
        }
        <div className="content-description">
          <div className="card-description-wrapper">
            <header>
              <div className="post-meta">
                <div className="is-details">
                  <p className="is-date">
                    {publicationDate}
                  </p>
                  <p className="is-reading-time">
                    <span className="sr-only">, Time to read: </span>
                    <span className="is-readtime"><SvgIcon className="clock-icon" useHref="icon-clock" />{timeToRead} min</span>
                    <span className="sr-only">,</span>
                  </p>
                </div>
                <Link to={slug} className="card-title-link hover-underline" tabIndex="-1">
                  <h3 className="card-title">
                    {language && language === 'pl' && (
                      <img
                        src={plFlag}
                        title="Polish content"
                        alt="Polish content"
                        className="flag-icon"
                      />
                    )}
                    {title}
                  </h3>
                </Link>
                {authors ? (
                  <span className="is-author">
                    {' '}
                    by
                    {authors.map((author, index) => (
                      <span className="author-link" key={author}>
                        {index ? ', ' : ' '}
                        <Link
                          to={`/authors/${kebabCase(author)}/`}
                          tabIndex="-1"
                        >
                          {author}
                        </Link>
                      </span>
                    ))}
                  </span>
                ) : null}
              </div>
            </header>
            <p className="card-description">
              {summary ? summary.substr(0, 220) : null} (...){' '}
            </p>
          </div>
          <div className="card-read-more">
            <Link
              className="button button-more"
              to={slug}
              tabIndex="-1"
            >
              <span className="sr-only">{title}.</span>
              Keep reading
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

BlogPostCard.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  isFeatured: PropTypes.bool,
  featuredImage: PropTypes.object,
  summary: PropTypes.string,
  authors: PropTypes.array,
  timeToRead: PropTypes.number,
  language: PropTypes.string,
};

export default BlogPostCard;

export const query = graphql`
  fragment BlogPostCardFragment on MarkdownRemark {
    id
    timeToRead
    fields {
      slug
    }
    frontmatter {
      authors
      title
      summary
      templateKey
      date(formatString: "MMMM DD, YYYY")
      featuredpost
    }
    ...featuredImage
  }
`;
